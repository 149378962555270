#blog {
	background: #efe1bd;
	padding-bottom: 5%;
    height: 100vh;
}

.section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1F1F1F;
    margin-bottom: 1rem;
}

.blog-item {
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;
    background-color: #1F1F1F;
    color: #efe1bd;
    padding: 1rem;
}